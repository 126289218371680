<template>
  <!-- 直播详情 -->
  <div class="livedetail public_main_bgc">
    <StudioIndex v-if="is_show_head==1"></StudioIndex>
    <div class="w_d center_one">
      <div class="navBox">
        <p>
          当前位置：研修中心&nbsp;>&nbsp;
          <span @click="toList()">教研直播</span>&nbsp;>&nbsp;
          <span class="font_orange">直播详情</span>
        </p>
      </div>
      <div class="video-module" v-if="videoSrc">
        <video v-if="videoSrc" :src="videoSrc" controls></video>
        <div class="videoTxt">
          <h4 class="ellipsis">{{ liveData.title }}</h4>
          <div class="df dfb">
            <div class="df videoDf">
              <div class="imgbox">
                <img :src="liveData.cover_url" alt="" />
              </div>
              <p>{{ liveData.username }}</p>
              <p>学段学科：{{ stage_subject }}</p>
            </div>
            <div v-if="liveData.user_id == userId">
              <dc-upload class="avatar-uploader" style="position: relative" ref="upload" :dcAutoUpload="true" :show-file-list="false" dcdom="ceshi2"
                :dcConfig="dcConfig" @dc-success="dcSuccess" @dc-progress="dcProgressTrue = false"
                v-if="dcConfig.key != '' && dcConfig.notify_url != ''" :accept="'.mp4,.MP4'">
                <span class="status_four_word fz15">更换视频</span>
              </dc-upload>
            </div>
          </div>
        </div>
      </div>
      <!-- 信息 -->
      <div class="one_content" v-if="!videoSrc">
        <div class="images">
          <img :src="liveData.cover_url" />
        </div>
        <div class="word">
          <div class="word_one">
            <p>{{ liveData.title }}</p>
            <!-- 编辑 -->
            <i class="el-icon-edit-outline cursor" v-if="ifshowStart() && liveData.audit_status != 4" @click="updateInforMation"></i>
            <!-- 删除 -->
            <i class="el-icon-delete cursor" @click="dialogVisible = true" v-if="ifshowStart()"></i>
          </div>
          <p class="newP ellipsis-1">
            {{ liveData.label }}
          </p>
          <!-- 详情 -->
          <div class="word_two">
            <p>
              <span style="color: #999999">主持人：</span>
              <span style="color: #333333">{{ liveData.username }}</span>
            </p>
            <span style="color: #999999; margin-left: 60px">直播时间：</span>
            <span>{{ liveData.start_date }} {{ liveData.start_time }} ~
              {{ liveData.end_time }}</span>
            <div v-if="liveData.audit_status == 5" class="start_one" style="background: red; border-color: red; color: #fff">
              未通过
            </div>
            <div v-if="liveData.audit_status != 5">
              <div class="start_one" v-if="liveData.status == '2'">未开始</div>
              <div class="start_two" v-if="liveData.status == '1'">进行中</div>
            </div>
            <div class="start_one" v-if="
                liveData.status == '3' ||
                liveData.status == '4' ||
                liveData.status == '5' ||
                liveData.status == '6'
              ">
              已结束
            </div>
          </div>
          <div class="word_three">
            <span>学段学科：</span>
            <p :title="stage_subject" style="
                width: 75%;
                white-space: pre-line;
                overflow: hidden;
                -webkit-line-clamp: 1;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              ">
              <span style="margin-right: 10px; color: #333333">{{
                stage_subject
              }}</span>
            </p>
          </div>
          <div class="infoList" v-if="uid == liveData.user_id && liveData.audit_status != 5">
            <p v-if="uid == liveData.user_id">
              <span>服务器：</span>{{ liveData.rtmp_url }}
              <i class="el-icon-document" v-clipboard:copy="liveData.rtmp_url" v-clipboard:success="onCopy" v-clipboard:error="onError"></i>

              <span style="margin-left: 120px">密钥：</span>{{ liveData.rtmp_key }}

              <i class="el-icon-document" v-clipboard:copy="liveData.rtmp_key" v-clipboard:success="onCopy" v-clipboard:error="onError"></i>
            </p>
            <!-- 码 -->
            <!-- <p>
              <span>课程访问码：</span>
              <span style="color: #ff8201; font-weight: bold">{{
                liveData.visit_code
              }}</span>
              <i
                class="el-icon-document"
                style="color: #ff8201; margin-left: 10px"
                v-clipboard:copy="liveData.visit_code"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              ></i>
            </p> -->
          </div>
          <!-- 详情 -->
          <div class="word_four" v-if="liveData.audit_status != 5">
            <div class="startup cursor" v-if="ifshowStart()" @click="stasrtupLive()">
              启动直播
            </div>
            <div class="shareBtn" @click="popupBox">
              <i class="el-icon-share"></i>
              分享
            </div>
            <el-dialog title="分享教研直播" :visible.sync="shareisShow" width="34%" class="dialogList">
              <h4>分享至：</h4>
              <div class="df mt20 mb20">
                <PublicShare></PublicShare>
              </div>
              <!-- 访问码邀请 -->
              <div v-if="false" class="top_one">
                <h4>访问码邀请</h4>
                <p>
                  将直播访问码分享给直播参与者，参与者可在教研直播频道输入访问码访问直播详情。
                </p>
                <div class="box1">
                  <div>
                    访问码：
                    <span>{{ liveData.visit_code }}</span>
                  </div>
                  <div v-clipboard:copy="liveData.visit_code" v-clipboard:success="onCopy" v-clipboard:error="onError" class="cursor">
                    复制访问码
                  </div>
                </div>
              </div>
              <!-- 邀请码end -->
              <!-- 分享链接 -->
              <div class="top_two">
                <h4>分享链接</h4>
                <p>点击复制链接并分享，参与者打开链接查看直播详情。</p>
                <div class="twoListss">
                  <p>分享链接：</p>
                  <div style="
                      background: rgba(247, 247, 247, 1);
                      color: rgba(52, 137, 254, 1);
                      margin-top: 10px;
                      margin-right: 20px;
                    " v-clipboard:copy="linkData" v-clipboard:success="onCopy" v-clipboard:error="onError" class="cursor">
                    {{ linkData }}
                  </div>
                </div>
              </div>
              <!-- 分享end -->
            </el-dialog>
            <div class="word_four_one" v-if="ifshowStart()">
              <p>
                <span>温馨提示：</span>
                <!-- 启动直播后，无法再编辑直播，请确保直播信息和内容无误后再启动。 -->
              </p>
              <p>
                若您未手动启动直播，当到达直播时间时，系统将会自动启动直播。
              </p>
            </div>

            <!-- <div class="into_live" v-if="ifshowinto()" @click="getintonowLive">
              进入教研直播
            </div> -->
            <div class="into_live" v-if="ifshowinto()" @click="ComeLive()">
              进入教研直播
            </div>
            <div class="into_word" v-if="ifshowinto()">
              已有{{ liveData.user_count }}人参与教研
            </div>

            <!-- <div class="into_live" v-if="ifshowend()">上传回放</div> -->
            <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false" dcdom="ceshi9" :dcConfig="dcConfig"
              @dc-success="dcSuccess" @dc-progress="dcProgressTrue = false" v-if="
                dcConfig.key != '' &&
                dcConfig.notify_url != '' &&
                liveData.status == '3'
              " :accept="'.mp4,.MP4'">
              <div class="into_live" v-if="is_Upload">上传回放</div>
            </dc-upload>

            <div v-if="liveData.status == '4' && liveData.is_live_user == 1" class="status_four">
              <new-viewonline :viewSrc="liveData.playback_resource_url" :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id" :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext"
                :fileTitle="item.title" :fileSize="item.size" :fileTime="item.create_time">
                <div class="into_live">查看回放</div>
              </new-viewonline>
              <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false" dcdom="ceshi2" :dcConfig="dcConfig"
                @dc-success="dcSuccess" @dc-progress="dcProgressTrue = false" v-if="dcConfig.key != '' && dcConfig.notify_url != ''"
                :accept="'.mp4,.MP4'">
                <span class="status_four_word">更换视频</span>
              </dc-upload>
            </div>

            <div v-if="liveData.status == '5'">
              <!-- 参与者无显示内容 -->
            </div>
            <div v-if="liveData.status == '6' && liveData.is_live_user == 1">
              <new-viewonline :viewSrc="liveData.playback_resource_url" :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id" :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext"
                :fileTitle="item.title" :fileSize="item.size" :fileTime="item.create_time">
                <div class="into_live">查看回放</div>
              </new-viewonline>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 教研资源 -->
    <div class="two_content">
      <div class="w_d center_content">
        <!-- 直播指引组件 -->
        <liveGuide v-if="isCreate" />
        <div class="titleBox">
          <span>直播介绍</span>
          <div class="word rich_text_div_img" v-html="liveData.presentation" style="
              font-size: 16px;
              font-family: Microsoft YaHei Regular,
                Microsoft YaHei Regular-Regular;
              font-weight: 400;
              text-align: left;
              color: #333333;
              margin-top: 29px;
              overflow: auto;
            "></div>
        </div>
        <!-- 直播介绍 -->

        <!-- 教研资源 -->
        <div class="resource">
          <div class="tBox">
            <span>教研资源</span>
            <div v-if="
                liveData.resource_data && liveData.resource_data.length == 0
              " style="
                font-size: 16px;
                color: #666666;
                margin-left: 60px;
                margin-top: 30px;
                padding-bottom: 30px;
                widht: 100%;
                text-align: center;
              ">
              暂无数据..
            </div>
          </div>
          <el-card shadow="hover" :body-style="{ padding: '0px' }" class="resource_card" v-for="(item, index) in liveData.resource_data" :key="index">
            <!-- //研修资源列表 -->
            <div class="resource_block">
              <div class="images">
                <img :src="onShowImages(item.mime)" />
              </div>
              <div class="resource_word">
                <p class="p_one">{{ item.resource_name }}</p>
                <div>
                  <p class="name_yangshi">{{ item.user_name }}</p>
                  <span>上传于</span>
                  <span>{{ item.create_time }}</span>
                </div>
              </div>
              <div class="viewonline cursor">
                <new-viewonline :viewSrc="item.view_url" :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id" :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext" :itemData="item"
                  :fileTitle="item.title" :fileSize="item.size" :fileTime="item.create_time">
                  <div class="viewonline cursor" style="width: 80px">
                    在线查看
                  </div>
                </new-viewonline>
              </div>

              <!-- <div class="sign">
                <i class="el-icon-setting"></i>
              </div> -->
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- 讨论记录部分 -->
    <div class="three_content" v-if="liveData.status == '3'">
      <div class="center_content w_d">
        <div class="content_one">教研讨论记录</div>
        <!-- 缺省图 -->
        <div class="text_box">
          <!-- <img src="../../../assets/images/nodata.png" alt=""> -->
          <CommonEmpty hVal="100%"></CommonEmpty>
        </div>
      </div>
    </div>
    <!-- end -->
    <!-- 心得体会 -->
    <div class="four_content" v-if="liveData.status == '3'">
      <div class="center_content w_d">
        <div class="content_one">心得体会</div>
        <!-- 体会心得缺省图 -->
        <div class="text_box">
          <!-- <img src="../../../assets/images/nodata.png" alt=""> -->
          <CommonEmpty hVal="100%"></CommonEmpty>
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- 删除对话框 -->
    <div class="mydialog">
      <el-dialog :visible.sync="dialogVisible" width="400px" :show-close="false" top="35vh">
        <div class="delete_dialog">
          <div class="delete_one">
            <div class="one_one">
              <i class="el-icon-close"></i>
            </div>
            <p>确定删除此直播吗？</p>
          </div>
          <div class="delete_two">
            <div class="two_cancel" @click="dialogVisible = false">取消</div>
            <div class="two_comfirm" @click="deleteLive()">确定</div>
          </div>
        </div>
      </el-dialog>
    </div>

    <el-dialog title="图片预览" :visible.sync="imageDialogVisible" width="50%">
      <el-image style="width: 100%; height: 100%" :src="imageurl" fit="fill"></el-image>
    </el-dialog>
    <el-dialog title="预览" :visible.sync="docxDialogVisible" width="50%" close-on-click-modal>
      <div style="width: 1000px; height: 1000px">
        <!-- <iframe
            :src="'http://view.xdocin.com/xdoc?_xdoc='+wordurl"
            style="overflow: auto; position: absolute; top: 0; right: 0; bottom: 0; left: 0; width: 100%; height: 100%"
          ></iframe>-->
        <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' + wordurl" style="
            overflow: auto;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
          "></iframe>
      </div>
    </el-dialog>
    <el-dialog title="预览视频" :visible.sync="shipinDialogVisible" width="50%">
      <video :src="shipingurl" type="video/mp4" poster="false.png" autoplay="autoplay" controls="controls" loop="-1"
        style="width: 100%; height: 100%">
        <p>你的浏览器不支持video标签.</p>
      </video>
    </el-dialog>
    <el-dialog title="PDF 预览" :visible.sync="viewVisible" width="50%" center @close="closeDialog">
      <div style="margin-bottom: 15px; text-align: right">
        <el-button type="primary" size="small" @click.stop="previousPage">上一页</el-button>
        <el-button type="primary" size="small" @click.stop="nextPage">下一页</el-button>
        <span>当前第{{ pdfPage }}页 / 共{{ pageCount }}页</span>
      </div>

      <div>
        <pdf :src="src" :page="pdfPage" @num-pages="pageCount = $event" @page-loaded="pdfPage = $event" style="display: inline-block; width: 100%">
        </pdf>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { onShowImages } from "@/utils/utils";
import pdf from "vue-pdf";
import { isHasStudioIdFn } from "@/utils/utils.js";
export default {
  components: {
    // "dc-upload": upload
    pdf,
  },
  data() {
    return {
      isCreate: false,
      //当前用户id
      uid: localStorage.getItem("id"),
      //学科学段字段
      stage_subject: "",
      //设置一个变量用来装分享链接
      linkData: "",
      //分享弹出框变量
      shareisShow: false,
      dialogVisible: false,
      // 直播id
      id: "",
      // 直播详情数据
      liveData: {},
      // 当前用户id
      userId: "",
      //PDF预览
      viewVisible: false,
      src: "",
      pdfPage: 1,
      pageCount: 0,
      //视频显示与否
      shipinDialogVisible: false,
      //视频url
      shipingurl: "",
      // 文档的url
      wordurl: "",
      // 文档显示与否
      docxDialogVisible: false,
      //图片url
      imageurl: "",
      // 图片预览的显示与隐藏
      imageDialogVisible: false,
      //金山云上传配置
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      // 上传资源表单
      form: {
        name: "",
        resource_id: "",
        // resource_name: "",
        stagedata: "",
        subjectdata: "",
        gradedata: "",
        resourcetypesdata: "",
        versiondata: "",
        chapterdata: "",
        areadata: "",
        yeardata: "",
      },
      videoSrc: "",
      is_show_head: null,
    };
  },
  created() {
    if (this.$route.query.is_show_head) {
      if (isHasStudioIdFn(this)) {
        this.is_show_head = this.$route.query.is_show_head
      };
    };
    this.init();
    this._getAddress();
    //从路由得到当前路由
    this.linkData = "https://hlwjy.dc-it.cn/#" + this.$route.fullPath;
  },
  computed: {
    //是否显示上传回放视频
    is_Upload() {
      let uid = localStorage.getItem("id");
      if (this.liveData.status == "3" && this.liveData.user_id == uid) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    fenxiang(val) {
      if (val == 1) {
        let urlData = `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${this.linkData}&sharesource=qzone&title=${this.liveData.title}&pics=${this.liveData.cover_url}&summary=${this.liveData.label}`;
        window.open(urlData, "_blank");
      }
      if (val == 3) {
        let urlData = `https://connect.qq.com/widget/shareqq/index.html?url=${this.linkData}&sharesource=qzone&title=${this.liveData.title}&pics=${this.liveData.cover_url}&summary=${this.liveData.label}`;
        window.open(urlData, "_blank");
      }
    },
    // 初始化函数
    init() {
      this.id = this.$route.query.lid;
      this.userId = localStorage.getItem("id");

      this.getLiveDetail();
    },
    onShowImages,
    //路由
    toList() {
      this.$router.push({
        path: "/researchCenter",
        query: {
          navActive: 2,
        },
      });
    },
    // 请求后台获取直播详情数据
    async getLiveDetail() {
      let res = await this.$axios.get(
        "index/TeachingStudioLive/detailsTeachingStudioLive",
        {
          params: {
            id: this.id,
          },
        }
      );
      var resdata = res.data;
      this.liveData = resdata.data;
      //学段学科
      this.stage_subject = resdata.data.subject_data
        .map((item) => {
          return item.stage_name + "-" + item.subject_name;
        })
        .toString();
      this.isCreate = this.liveData.user_id == this.userId ? true : false;
      this.videoSrc = this.liveData.playback_resource_url;
      console.log(res, "直播详情数据");
    },

    //修改进入直播
    ComeLive() {
      let token = localStorage.getItem("token");
      if (token) {
        //从路由获取id
        let live_id = this.$route.query.lid;
        this.$router.push({
          path: "/MyLive",
          query: {
            id: live_id,
            is_form: 2,
          },
        });
      } else {
        this.$message.warning("请先登录，再进行操作。");
      }
    },

    //粘贴和复制
    onCopy() {
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    onError() {
      this.$message.error("复制失败");
    },
    //弹出分享的弹出框
    popupBox() {
      this.shareisShow = true;
    },
    //跳转管理端修改直播信息页面
    updateInforMation() {
      this.$router.push({
        path: "/newLive",
        query: {
          id: this.id,
        },
      });
      // 从路由原信息得到直播id传过去
      // this.$router.push({
      //   path: "/AdminLiveDetail",
      //   query: {
      //     lid: this.id,
      //   },
      // });
    },

    // 判断是否显示启动按钮函数
    ifshowStart() {
      if (this.userId == this.liveData.user_id) {
        if (this.liveData.status == "2") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // 判断是否显示进入按钮函数
    ifshowinto() {
      if (this.ifshowStart() == false) {
        if (this.liveData.status == "1" || this.liveData.status == "2") {
          return true;
        } else {
          return false;
          // console.log("22")
        }
      } else {
        return false;
        // console.log("11")
      }
    },
    // 判断是否显示上传回放按钮函数
    ifshowend() {
      if (this.ifshowStart() == false) {
        if (this.liveData.status == "3") {
          return true;
        } else {
          return false;
          // console.log("22")
        }
      } else {
        return false;
        // console.log("11")
      }
    },
    // 请求后台删除直播函数  live/delLive/${this.id}/${this.userId}
    deleteLive() {
      let obj = {
        id: this.id,
        user_id: this.userId,
      };
      this.$axios
        .delete("index/TeachingStudioLive/deleteTeachingStudioLive", {
          params: obj,
        })
        .then(() => {
          this.$router.push("/researchCenter?navActive=2");
        });
    },
    // 展示图片
    showimage(item) {
      console.log(item.url);
      // this.imageurl = item.url;
      // this.imageDialogVisible = true;
      //获取最后一个.的位置
      var index = item.url.lastIndexOf(".");
      //获取后缀
      var ext = item.url.substr(index + 1);
      console.log(ext);
      if (this.isAssetTypeAnImage(ext)) {
        this.imageurl = item.url;
        this.imageDialogVisible = true;
      }
      if (this.isAssetTypeAnword(ext)) {
        this.wordurl = item.url;
        this.docxDialogVisible = true;
      }
      if (this.isAssetTypehshipin(ext)) {
        this.shipingurl = item.url;
        this.shipinDialogVisible = true;
      }
      if (this.isAssetTypehpdf(ext)) {
        this.src = item.url;
        this.viewVisible = true;
      }
    },
    //判断是否图片
    isAssetTypeAnImage(ext) {
      return ["png", "jpg", "jpeg", "bmp", "gif", "webp", "psd", "svg", "tiff"].includes(ext.toLowerCase());
    },
    // 判断是否是文档
    isAssetTypeAnword(ext) {
      return ["epub", "doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(ext.toLowerCase());
    },
    // 判断是否是视频
    isAssetTypehshipin(ext) {
      return ["avi", "wmv", "mpeg", "mp4", "m4v", "flv", "f4v", "rmvb", "rm", "3gp", "vob"].includes(ext.toLowerCase());
    },
    // 判断是否是pdf
    isAssetTypehpdf(ext) {
      return ["pdf"].includes(ext.toLowerCase());
    },
    //PDF预览
    previewPDF(row) {
      this.src = pdf.createLoadingTask(
        documentConstants.previewPDFUrl + row.contractId
      );
      this.src.then((pdf) => {
        this.viewVisible = true;
      });
    },
    //关闭窗口初始化PDF页码
    closeDialog() {
      this.pdfPage = 1;
    },

    //PDF改变页数
    previousPage() {
      var p = this.pdfPage;
      p = p > 1 ? p - 1 : this.pageCount;
      this.pdfPage = p;
    },
    nextPage() {
      var p = this.pdfPage;
      p = p < this.pageCount ? p + 1 : 1;
      this.pdfPage = p;
    },
    // 请求后台进入直播间
    getintonowLive() {
      const loading = this.$loading({
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        loading.close();
      }, 2000);
      this.$axios.get(`TeachingStudiolive/room/${this.id}`).then((res) => {
        var resdata = res.data;
        var url = resdata.data.url;
        window.open(url);
      });
    },
    // 请求后台启动直播函数
    stasrtupLive() {
      this.$axios
        .put(`TeachingStudiolive/room/${this.id}/start`)
        .then((res) => {
          this.init();
        });
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      console.log(data);
      if (!data.upload) return;
      var resData = data.upload.CompleteMultipartUploadResult;
      resData.ETag = resData.ETag.replace(/"/g, "");

      this.form.name = data.filename;

      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: resData.ETag,
        },
      });

      this.form.resource_id = res.data.data.id;
      let obj = {
        live_id: this.id,
        resource_id: this.form.resource_id,
      };

      //上传回放视频
      this.$axios.put("TeachingStudiolive/uploadPlayback", obj).then((res) => {
        this.$message.success("上传成功");
        this.init();
      });
    },
  },
};
</script>

<style  lang="less" >
.livedetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  .center_one {
    .navBox {
      width: 1400px;
      display: flex;
      align-items: center;
      height: 48px;
      border-bottom: 1px solid #f0f0f0;
      p {
        font-size: 14px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        span:last-child {
          color: #ff8201;
        }
        span:first-of-type {
          cursor: pointer;
          &:hover {
            color: #3489fe;
          }
        }
      }
    }
    .one_content {
      background: #fff;
      display: flex;
      border-radius: 6px;
      padding: 30px;
      box-sizing: border-box;
      .images {
        //图片样式
        position: relative;
        z-index: 1;
        img {
          width: 520px;
          height: 264px;
          border-radius: 6px;
        }
      }
      .word {
        margin-left: 32px;
        .word_one {
          display: flex;
          align-items: center;
          margin-bottom: 17px;
          p {
            max-height: 58px;
            overflow: hidden;
            font-size: 22px;
            font-weight: 700;
          }
          i {
            font-size: 22px;
            margin-left: 15px;
            color: #009dff;
          }
        }
        .newP {
          margin-bottom: 15px;
          font-size: 16px;
          color: rgba(153, 153, 153, 1);
        }
        .word_two {
          display: flex;
          margin-bottom: 25px;
        }
        .word_three {
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          .start_one {
            width: 48px;
            height: 20px;
            border: 1px solid #999999;
            border-radius: 4px;
            margin-left: 17px;
            font-size: 12px;
            font-weight: 700;
            color: #999999;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .start_two {
            width: 48px;
            height: 20px;
            background: #3489fe;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 700;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 17px;
          }
          .start_for {
            width: 48px;
            height: 20px;
            background: #ff0000;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 700;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 17px;
          }
        }
        //推流信息样式
        .infoList {
          p {
            color: #3489fe;
            span {
              color: black;
            }
            margin-bottom: 15px;
          }
        }
        .word_four {
          display: flex;
          align-items: center;
          h4 {
            color: black;
          }
          //  邀请码
          .top_one {
            font-size: 14px;
            span:nth-of-type(1) {
              font-weight: bold;
            }
            p {
              margin: 10px 0;
            }
            .box1 {
              display: flex;
              margin-bottom: 10px;
              div {
                span {
                  font-size: 14px;
                  font-weight: normal;
                  color: #3489fe;
                }
              }
              div:nth-last-of-type(1) {
                margin-left: 20px;
                width: 90px;
                height: 20px;
                color: #ffffff;
                background: #3489fe;
                border-radius: 2px;
                text-align: center;
                line-height: 20px;
              }
            }
          }
          // 分享样式
          .top_two {
            p:nth-of-type(1) {
              margin: 10px 0;
            }
            .twoListss {
              display: flex;
              span {
                font-weight: normal;
              }
              div:nth-of-typ(1) {
                width: 150px;
                height: 50px;
                background: rgba(247, 247, 247, 1);
                line-height: 50px;
                text-align: center;
              }
              div:nth-of-typ(2) {
                width: 100px;
                height: 50px;
                background: rgba(52, 137, 254, 1);
                color: #ffffff;
              }
            }
          }
          .dialogList {
            span:nth-last-of-type(1) {
              font-weight: bold;
              font-size: 16px;
            }
          }
          .el-dialog__header {
            border-bottom: 1px solid #ebebeb;
          }
          // 分享按钮
          .shareBtn {
            width: 129px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            border: 1px solid #3489fe;
            color: #3489fe;
            cursor: pointer;
            border-radius: 8px;
            font-size: 18px;
            font-weight: 700;
            margin-right: 30px;
          }
          .startup {
            width: 129px;
            height: 46px;
            background: #3489fe;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 700;
            color: #ffffff;
            margin-right: 20px;
          }
          .word_four_one {
            span {
              font-weight: 700;
            }
            font-size: 14px;
            font-weight: 400;
            color: #ff0607;
            p:nth-child(1) {
              margin-bottom: 5px;
            }
          }
          .into_live {
            width: 169px;
            height: 46px;
            background: #ff8202;
            border-radius: 8px;

            // width: 169px;
            // height: 46px;
            // background: #ff8201;
            // background: #3489fe;
            // border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 700;
            color: #ffffff;
            cursor: pointer;
          }
          .into_word {
            margin-left: 31px;
            margin-top: -2px;
            font-size: 16px;
            font-family: Microsoft YaHei Regular,
              Microsoft YaHei Regular-Regular;
            font-weight: 400;
            color: #999999;
            span {
              color: #ff8202;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .two_content {
    width: 100%;
    display: flex;
    justify-content: center;
    .center_content {
      margin-bottom: 24px;

      .titleBox {
        margin-top: 24px;
        margin-bottom: 24px;
        padding: 0 34px;
        padding-top: 36px;
        padding-bottom: 54px;
        font-size: 22px;
        background-color: #ffffff;
        border-radius: 6px;
        span {
          width: 87px;
          font-size: 22px;
          font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
          font-weight: 700;
          text-align: left;
          color: #333333;
        }
        i {
          color: #009dff;
        }
        .word {
          border-radius: 6px;
        }
      }

      .resource {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        width: 1400px;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: #ffffff;
        padding: 0 34px;
        .tBox {
          width: 1400px;
          margin-bottom: 30px;
          padding-top: 36px;

          border-radius: 6px;
          span {
            width: 88px;
            font-size: 22px;
            font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
            font-weight: 700;
            text-align: left;
            color: #333333;
          }
          i {
            color: #009dff;
          }
          .word {
            font-size: 16px;

            // border-radius: 6px;
            // padding: 47px 36px 54px 33px;
            p {
              font-family: Microsoft YaHei Regular,
                Microsoft YaHei Regular-Regular;
              font-weight: 400;
              text-align: left;
              color: #333333;
            }
          }
        }
        .resource_card {
          margin-bottom: 26px;
          .resource_block {
            width: 654px;
            height: 100px;
            background: #ffffff;
            display: flex;
            align-items: center;
            position: relative;

            .images {
              margin-left: 34px;
            }
            .resource_word {
              margin-left: 30px;
              .p_one {
                width: 316px;
                font-weight: 700;
                margin-bottom: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              div:nth-child(2) {
                font-size: 14px;
                color: #666666;
                display: flex;
                span {
                  margin-right: 10px;
                }
                .name_yangshi {
                  max-width: 100px;
                  overflow: hidden;
                  margin-right: 10px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
            .viewonline {
              margin-left: 53px;
              font-weight: 700;
              color: #3489fe;
            }
            .download {
              margin-top: 43px;
              margin-left: 53px;
              color: #3489fe;
              font-weight: 700;
              .dow_one {
                color: #3489fe;
                font-weight: 700;
                font-size: 16px;
              }
            }
            .sign {
              background-color: #999999;
              width: 31px;
              height: 31px;
              position: absolute;
              bottom: 0px;
              right: 0px;
              border-top-left-radius: 18px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #ffffff;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .three_content {
    width: 100%;
    display: flex;
    justify-content: center;

    .center_content {
      border-radius: 6px;
      .text_box {
        font-size: 16px;
        color: #666666;
        margin-left: 60px;
      }
      .content_one {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 40px;
      }
      .content_two {
        background: #ffffff;
        border-radius: 6px;
        padding-top: 20px;
        padding-left: 35px;
        padding-right: 10px;
        overflow: hidden;
        .block {
          margin-bottom: 30px;
          p:nth-child(1) {
            margin-bottom: 13px;
          }
          p:nth-child(2) {
            line-height: 32px;
          }
        }
      }
    }
  }
  .four_content {
    width: 100%;
    display: flex;
    justify-content: center;
    // padding-top: 60px;
    .center_content {
      border-radius: 6px;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      width: 1400px;
      margin: 0 auto;
      padding: 20px 20px;
      background: #fff;
      margin-bottom: 20px;
      .text_box {
        font-size: 16px;
        color: #666666;
        //  margin-left: 60px;
        margin-bottom: 40px;
        background: #fff;
        //评论框
        .new_release {
          margin-bottom: 37px;
          background: #ffffff;
          .seven_left {
            display: flex;
            flex-direction: column;
            align-items: center;
            .images {
              margin-bottom: 13px;
              img {
                width: 50px;
                height: 50px;
              }
            }
          }
          .seven_right {
            // width: 890px;
            width: 100%;
            height: 184px;
            border: 1px solid #ececec;
            border-radius: 4px;
            margin-right: 30px;
            padding-top: 20px;
            .release {
              width: 92px;
              height: 34px;
              background: #fa8c15;
              border-radius: 4px;
              font-weight: 700;
              color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
              float: right;
              margin-right: 13px;
            }
            .el-textarea__inner {
              border: none;
            }
          }
        }
      }
      .content_one {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 34px;
      }
      .content_two {
        background: #ffffff;
        border-radius: 6px;
        padding-top: 50px;
        padding-left: 50px;
        padding-right: 300px;
        padding-bottom: 30px;
        margin-bottom: 30px;
        // width:916px;
        // padding-left:50px;
        .nine {
          .comment_block {
            margin-right: 32px;
            margin-bottom: 40px;
            .block_one {
              display: flex;
              align-items: center;
              margin-bottom: 14px;
              .images {
                margin-right: 19px;
                img {
                  width: 50px;
                  height: 50px;
                }
              }
              .one_word {
                font-size: 14px;
                p:nth-child(1) {
                  color: #fa8c15;
                  margin-bottom: 5px;
                }
                p:nth-child(2) {
                  color: #9a9ea5;
                }
              }
            }
            .block_two {
              margin-left: 69px;
              margin-bottom: 21px;
              p {
                line-height: 30px;
              }
            }
            .block_three {
              display: flex;
              justify-content: flex-end;
              font-size: 14px;
              color: #9a9ea5;
              margin-bottom: 26px;
              .three_one {
                margin-right: 26px;
              }
              i {
                margin-right: 6px;
              }
            }
            .block_four {
              display: flex;
              justify-content: flex-end;
              .reply_block {
                width: 858px;
                // height: 542px;
                background: #f7f7f7;
                border-radius: 8px;
                padding: 30px 27px 34px 30px;
                .reply_block_one {
                  .reply_one {
                    display: flex;
                    align-items: flex-end;
                    margin-bottom: 19px;
                    .images {
                      margin-right: 14px;
                      width: 50px;
                      height: 50px;
                      border-radius: 50%;
                      overflow: hidden;
                      img {
                        width: 50px;
                        height: 50px;
                      }
                    }
                    .reply_one_word {
                      font-size: 14px;
                      p:nth-child(1) {
                        margin-bottom: 5px;
                        span:nth-child(odd) {
                          color: #fa8c15;
                        }
                        span:nth-child(2) {
                          margin: 0px 10px;
                        }
                      }
                      p:nth-child(2) {
                        color: #9a9ea5;
                      }
                    }
                  }
                  .reply_two {
                    margin-left: 64px;
                    margin-bottom: 17px;
                    p {
                      line-height: 30px;
                    }
                  }
                  .reply_three {
                    display: flex;
                    justify-content: flex-end;
                    font-size: 14px;
                    color: #9a9ea5;
                    margin-bottom: 26px;
                    .three_one {
                      margin-right: 26px;
                    }
                    i {
                      margin-right: 6px;
                    }
                  }
                  .reply_four {
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 30px;
                    .reply_four_hengxian {
                      width: 794px;
                      // height: 1px;
                      border-top: 1px dashed #ececec;
                    }
                  }
                  .reply_five {
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 25px;
                    .reply_five_block {
                      width: 792px;
                      height: 100px;
                      background: #ffffff;
                      border-radius: 8px;
                      padding-top: 10px;
                      .release_block {
                        width: 92px;
                        height: 36px;
                        background: #fa8c15;
                        border-radius: 4px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #ffffff;
                        float: right;
                        margin-right: 20px;
                      }
                      /deep/ .el-textarea__inner {
                        border: none;
                      }
                    }
                  }
                }
                .reply_block_one:last-child {
                  .reply_four {
                    margin-bottom: 0px;
                    .reply_four_hengxian {
                      border-top: 0px;
                    }
                  }
                }
              }
            }
            .block_five {
              width: 914px;
              height: 1px;
              border-top: 1px dashed #ececec;
              margin-top: 26px;
              float: right;
            }
            .comment_block_reply {
              display: flex;
              justify-content: flex-end;
              margin-bottom: 25px;
              .block {
                width: 914px;
                height: 100px;
                background: #ffffff;
                border: 1px solid #ececec;
                border-radius: 8px;
                padding-top: 10px;
                .release {
                  width: 92px;
                  height: 36px;
                  background: #fa8c15;
                  border-radius: 4px;
                  color: #ffffff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  float: right;
                  margin-right: 20px;
                }
                /deep/ .el-textarea__inner {
                  border: none;
                }
              }
            }
          }
          .comment_block:last-child {
            .block_five {
              border-top: 0px;
              margin-top: 0px;
            }
          }
        }
      }
    }
  }

  .status_four {
    display: flex;
    align-items: center;
  }
  .status_four_word {
    margin-left: 20px;
    color: #3489fe;
    cursor: pointer;
  }
}
.have_auto {
  overflow: auto;
}

.video-module {
  background: rgba(27, 33, 40, 1);
  border-radius: 8px;
  width: 100%;
  padding: 22px;
  box-sizing: border-box;
  video {
    width: 100%;
    height: 600px;
  }
  .videoTxt {
    margin-top: 30px;
    h4 {
      font-size: 22px;
      font-weight: bold;
      color: rgba(234, 234, 234, 1);
    }
    .videoDf {
      margin-top: 16px;
      align-items: center;
      .imgbox {
        margin-right: 12px;
        width: 25px;
        height: 33px;
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
      }
      p {
        margin-right: 25px;
        font-size: 16px;
        color: rgba(205, 205, 205, 1);
      }
    }
  }
}
</style>